@import "./normalize.scss";
@import url("https://fonts.googleapis.com/css?family=Fira+Sans:300,300i,400,400i,700|Merriweather:300i");

$yellow: #ffcf3d;
$lightYellow: #fff0c5;
$superLightYellow: #fffaec;
$darkYellow: #b79223;
$violet: #610062;
$superLightViolet: #ffe9ff;

html {
    background-color: #fff;
    font-family: "Fira Sans", Arial, sans-serif;
    // font-size: 16px;
    font-weight: 300;
    line-height: 1.7;
    color: #525252;
}

body {
    margin: 0;
}

img {
    max-width: 100%;
}

header {
    min-height: 400px;
    background-image: url("../img/header-bg.jpg");
    background-size: 100%;
    background-position: 50% 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // Barre de navigation
    nav {
        background-color: rgba(0, 0, 0, 0.3);
        font-family: "Merriweather", serif;
        font-style: italic;
        font-weight: 300;
        color: #fff;
        align-self: stretch;

        ul {
            margin: 0;
            padding: 0;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            list-style: none;
        }

        li {
            margin: 0.5rem;
            /* // Dernier élément du menu intercalé à droite
            &:last-child {
                margin-left: auto;
                margin-right: 1rem;
            }
            // Centrage du reste du menu
            &:first-child {
                margin-left: auto;
            } */
        }

        a {
            &::before {
                transition: all 0.2s;
                transform: scale(0.5);
                opacity: 0;
                content: "";
                display: block;
                position: absolute;
                z-index: -1;
                top: 0.5em;
                left: 0;
                right: 0;
                bottom: 0.5em;
                background-color: #fff;
                border-radius: 2.8rem;
            }

            &:link,
            &:visited {
                position: relative;
                z-index: 1;
                display: block;
                padding: 1.075rem 1rem;
                text-decoration: none;
                // text-transform: uppercase;
                font-size: 1.1rem;
                color: $lightYellow;
                transition: color 0.2s;
            }

            &:hover {
                color: #000;
                &::before {
                    transform: scale(1);
                    opacity: 1;
                }
            }

            &:active {
                &::before {
                    background-color: $yellow;
                    transition: none;
                }
            }

            &.menu-cta {
                color: #000;
                &::before {
                    transform: scale(1);
                    opacity: 1;
                    background-color: $yellow;
                }

                &:hover::before {
                    background-color: #fff;
                }
                &:active::before {
                    background-color: $yellow;
                }
            }
        }
    }

    // Titre du menu
    h1 {
        margin: 0;
        font-family: "NSimSun", "新宋体", STHeiti, "华文黑体", sans-serif;
        font-size: 42px;
        font-weight: 300;
        font-style: normal;
        text-transform: uppercase;
        text-align: center;
        text-shadow: 6px 6px 10px rgba(23, 27, 2, 0.75);
        color: $lightYellow;
    }

    small {
        margin: 0 0 4rem;
        color: $lightYellow;
        font-family: "NSimSun", "新宋体", STHeiti, "华文黑体", sans-serif;
        font-size: 26px;
        text-shadow: 1px 1px 2px rgba(23, 27, 2, 0.75);
    }

    // Logo
    #logo-container {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 0;
            display: block;
            background-color: $violet;
            border-radius: 500px;
            width: 225px;
            height: 225px;
            transform: translate(-50%, -50%);
        }

        #logo {
            position: relative;
            z-index: 1;
            -webkit-animation: spin 64s linear infinite;
            animation: spin 64s linear infinite;
        }

        @keyframes spin {
            0% {
                transform: rotate(0);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
}

a {
    &:link,
    &:visited {
        color: $darkYellow;
        text-decoration: underline;
    }
    &:hover {
        color: $yellow;
        text-decoration: none;
    }
    &:active {
        color: $violet;
    }
}

h1,
h2,
h3 {
    font-family: Merriweather, serif;
    font-weight: 300;
    font-style: italic;
}

h1 {
    font-size: 1.8rem;

    &:first-child {
        margin-top: 0;
    }
}

main {
    text-align: center;

    ul {
        text-align: left;
    }
}

.container {
    box-sizing: border-box;
    max-width: 60rem;
    margin: 0 auto;
}

.container-fluid {
    box-sizing: border-box;
    margin: 0;
}

.btn {
    background-color: $yellow;
    border: none;
    cursor: pointer;
    border-radius: 2.8rem;
    display: inline-block;
    padding: 0.5375rem 1rem;
    font-family: "Merriweather", serif;
    font-size: 1.1rem;
    font-style: italic;
    font-weight: 300;
    color: #000;
    transition: all 0.2s;
    line-height: 1.6;

    &.btn-small {
        font-size: 0.8rem;
        padding: 0.3rem 0.9rem;
    }

    &:link {
        text-decoration: none;
    }

    &:hover:not[disabled] {
        outline: none;
        background-color: $lightYellow;
        box-shadow: inset 0 0 3px $yellow;
    }

    &:active:not[disabled] {
        outline: none;
        background-color: $yellow;
        transition: none;
    }

    &:focus:not[disabled] {
        outline: none;
    }

    &[disabled] {
        background-color: rgb(235, 235, 219);
        color: silver;
        cursor: not-allowed;
    }
}

section {
    padding: 3rem 0;

    &.borderless {
        padding-top: 0;
        padding-bottom: 0;
    }
    &.borderless-top {
        padding-top: 0;
    }
    &.borderless-bottom {
        padding-bottom: 0;
    }

    &:nth-child(even) {
        background-color: #f6f6f6;
    }

    &.hero {
        background-color: $violet;
        color: #e8e8e8;

        h1 {
            color: #fff;
        }
    }

    h1 {
        margin-top: 0;
    }
    h1 + p {
        margin-top: 0;
    }
}

.grid {
    display: grid;
    grid-template-columns: 50% 50%;
}

.align-items-center {
    align-items: center;
}

.flex {
    display: flex;
    justify-content: center;

    > * {
        flex: 1;
    }

    &.flex-wrap {
        flex-wrap: wrap;
    }

    &.grow-initial > * {
        flex: initial;
    }

    &.justify-start { justify-content: flex-start; }
    &.items-center { align-items: center; }

    &.gap-1 > * { margin: 0.2rem }
}

.float-left {
    float: left;
}
.float-right {
    float: right;
}

.emoji {
    font-family: apple color emoji, segoe ui emoji, noto color emoji, android emoji, emojisymbols, emojione mozilla, twemoji mozilla, segoe ui symbol;
}

#seances {
    .seance-image {
        margin: 0;
        box-sizing: border-box;
        line-height: 0;

        > img {
            object-fit: cover;
            min-width: 100%;
            min-height: 100%;
        }
    }

    /* .seance-body {
        padding: 2rem;
        margin: 0;
        box-sizing: border-box;
    } */
}

#reikimap {
    height: 100%;
}

/* .well {
    background-color: $lightYellow;
    border-top-right-radius: 3.5rem;
    border-bottom-left-radius: 3.5rem;
    margin: 1.2rem 0 2rem 0;
    padding: 1.5rem 2rem;
    position: relative;
    color: $darkYellow;

    .well-title {
        // color: $darkYellow;
        width: 70%;
        margin: 0 auto 0.5rem auto;
        padding-bottom: 0.25rem;
        font-weight: bold;
        border-bottom: thin solid $darkYellow;
    }

    p:last-child {
        margin: 0;
    }

    &::before {
        position: absolute;
        top: 0;
        left: -1rem;
        transform: translate(0, -35%);
        display: block;
        content: "";
        background-image: url("../img/logo.png");
        background-size: contain;
        width: 150px;
        height: 150px;
    }
} */

table.waka {
    width: auto;
    margin: 0 auto;
    padding: 0;
    border-spacing: 0;

    td {
        padding: 0 2rem;
        font-size: 18px;
        font-weight: bold;
    }

    td:nth-child(2) {
        font-family: "NSimSun", "新宋体", STHeiti, "华文黑体", sans-serif;
        font-size: 24px;
    }
}

.well {
    background-color: $superLightYellow;
    margin-top: 0;
    margin-bottom: 3rem;
    border: thin solid $lightYellow;
    border-top-right-radius: 3.5rem;
    border-bottom-left-radius: 3.5rem;
    color: $darkYellow;
    position: relative;

    .well-title {
        margin-top: 1rem;
        font-weight: 600;
    }
}

.mt-0 {
    margin-top: 0;
}
.mt-1 {
    margin-top: 1rem;
}
.mt-2 {
    margin-top: 2rem;
}
.mb-0 {
    margin-bottom: 0;
}
.mb-1 {
    margin-bottom: 1rem;
}
.mb-2 {
    margin-bottom: 2rem;
}
.pb-1 {
    padding-bottom: 1rem;
}
.pt-1 {
    padding-top: 1rem;
}
.pb-2 {
    padding-bottom: 2rem;
}
.pt-2 {
    padding-top: 2rem;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-left {
    text-align: left;
}
.text-weight-normal {
    font-weight: 300;
}
.text-uppercase {
    text-transform: uppercase;
}

.kenjis {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    > li {
        background-color: $lightYellow;
        margin: 0.2rem 0.4rem;
        padding: 0.5rem 1.5rem 1.5rem 1.7rem;
        text-align: center;
        border-top-right-radius: 4rem;
        border-bottom-left-radius: 4rem;

        > em {
            font-size: 26px;
            white-space: nowrap;
        }

        > strong {
            white-space: nowrap;
            font-size: 32px;
        }
    }
}

.kenji {
    font-family: "NSimSun", "新宋体", STHeiti, "华文黑体", sans-serif;
}

.praticien {
    clear: both;
    text-align: left;

    &:nth-of-type(2n) {
        text-align: right;

        .praticien-photo {
            float: right;
        }
    }

    .praticien-photo {
        margin: 0 2rem 0;
        text-align: center;
        float: left;
        shape-outside: circle(50%);
    }

    .praticien-photo > img {
        filter: grayscale(100%);
        border-radius: 90rem;
        max-width: 12rem;
    }
}

.offer {
    background-color: $lightYellow;
    margin: 1rem;
    padding: 1rem 0.25rem;
    border-top-right-radius: 5rem;
    border-bottom-left-radius: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .offer-title {
        margin-top: 0;
    }

    .offer-price {
        font-weight: 600;
        font-size: 1.4rem;
        margin-top: auto;
        margin-bottom: 0.5rem;
    }
}

.cta {
    text-align: center;
    margin-top: 2rem;
}

.certif-container {
    display: grid;
    grid-template-columns: 50% 50%;
}
.certif {
    padding: 1rem;
    margin: 1rem;
    background-color: $lightYellow;
    border-radius: 1rem;

    .certif-title {
        margin-bottom: 0;
    }
    .certif-image {
        box-sizing: border-box;
        border: 0.5rem solid $darkYellow;
        border-radius: 1rem;
    }
}

/**
 * Persons
 */

.person {
    text-align: left;
    clear: left;
    max-width: 30rem;
    margin: 2rem 1rem;
    overflow: auto;
    // outline: thin dashed #c0c0c0;

    .person-image {
        float: left;
        width: 10rem;
        height: 10rem;
        margin-right: 0.75rem;
        object-fit: fill;
        // clip-path: circle(5rem at 50% 5rem);
        border-radius: 10rem;
    }

    .person-body:only-child .person-name {
        text-align: center;
        padding: 0.5rem 0;
    }

    .person-name {
        font-family: Merriweather, serif;
        font-weight: 700;
        font-style: italic;
        font-size: 1rem;
        .person-name__date {
            font-style: italic;
        }
    }
}

#lignees {
    position: relative;
    z-index: 2;
}

/* .lignee-occident {
    background-color: $superLightYellow;
}
.lignee-japan {
    background-color: $superLightViolet;
} */

select,
input[type="text"],
input[type="email"],
input[type="tel"] {
    border: thin solid #c0c0c0;
    border-radius: 0.2rem;
    padding: 0.55rem 0.75rem;
    letter-spacing: 0.0625rem;
    box-sizing: border-box;

    &:focus {
        outline: none;
    }
}

select {
    padding: 0.35rem 0;
}

fieldset {
    border: thin solid $yellow;
    background-color: $lightYellow;

    legend {
        font-family: Merriweather, serif;
        font-weight: 600;
        font-style: italic;
        font-size: 1.2rem;
        color: #000;
        text-align: center;
    }
}

#form-rdv {
    .field-valid {
        border: thin solid rgb(0, 180, 0);
        color: rgb(0, 180, 0);
    }
    .field-invalid {
        border: 0.1rem solid #f55;
        color: #f55;
    }

    label ~ small {
        display: block;
        margin-top: -0.25rem;
        margin-bottom: 0.75rem;
        color: #f33;
        font-weight: 400;
    }

    .form-confirmation {
        text-align: center;
        margin: 0.5rem;
        padding: 2rem 1.5rem;
        border-radius: 1rem;

        &.form-confirmation__success {
            color: rgb(0, 180, 0);
            border: thin solid rgb(0, 180, 0);
            background-color: rgba(0, 180, 0, 0.15);
            hr {
                background-color: rgba(100, 180, 100, 0.4);
                border: thin solid rgba(100, 180, 100, 0.4);
                width: 62.5%;
            }
        }

        &.form-confirmation__error {
            color: rgb(180, 0, 0);
            border: thin solid rgb(180, 0, 0);
            background-color: rgba(180, 0, 0, 0.15);
            hr {
                background-color: rgba(180, 100, 100, 0.4);
                border: thin solid rgba(180, 100, 100, 0.4);
                width: 62.5%;
            }
        }

        & > :first-child {
            margin-top: 0;
        }
    }

    fieldset {
        margin: 0.5rem;
        border-radius: 1rem;
        padding: 2rem 1.5rem;
        max-width: calc(50% - 4rem);
        transition: all 0.7s;

        .dates-choice {
            th {
                font-size: 0.8rem;
                padding-bottom: 0.7rem;
                > strong {
                    display: block;
                    font-size: 1rem;
                    line-height: 1.1;
                }
            }

            tr > td {
                vertical-align: middle;
                text-align: center;

                > button {
                    color: #000;
                    text-decoration: none;
                    display: block;
                    padding: 0.5rem;
                    margin: 0.15rem 0.5rem;
                    width: calc(100% - 1rem);
                    background-color: $superLightYellow;
                    border-radius: 1rem;
                    border: none;
                    cursor: pointer;
                    box-sizing: border-box;

                    &:hover,
                    &:focus {
                        background-color: #fff;
                    }

                    &:active {
                        border: none;
                        outline: none;
                        background-color: $yellow;
                    }
                    &.selected {
                        border: none;
                        outline: none;
                        background-color: rgb(0, 180, 0);
                        color: white;
                    }

                    &[disabled] {
                        background-color: transparent;
                        cursor: not-allowed;
                        border-radius: 1rem;
                        opacity: 0.5;
                        text-decoration: line-through;
                    }
                }
            }
        }
    }
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.7s;
    overflow: hidden;
}
.slide-enter {
    opacity: 0;
    flex-grow: 0.0001;
}
.slide-enter-to {
    opacity: 1;
    flex-grow: 1;
}
.slide-leave {
    opacity: 1;
    flex-grow: 1;
}
.slide-leave-to {
    opacity: 0;
    flex-grow: 0.0001;
}

.fade-enter {
    opacity: 0;
}
.fade-enter-active {
    transition: all 0.5s;
    transform-origin: 50% 50%;
}
.fade-enter-to {
    opacity: 1;
}
.fade-leave {
    transform: scale(1);
    opacity: 1;
}
.fade-leave-active {
    transition: all 0.25s;
    transform-origin: 50% 50%;
}
.fade-leave-to {
    transform: scale(0);
    opacity: 0;
}

form {
    text-align: left;
    label {
        display: block;
        font-weight: 500;
        text-align: left;
    }

    .form-control {
        text-align: left;
        margin-bottom: 0.5rem;
        &.input-block {
            box-sizing: border-box;
            display: block;
            width: 100%;
        }
    }
}

.spinner {
    margin: 0 2em;
    width: 1em;
    height: 1em;
    border: 0.4em solid rgb(185, 185, 171);
    border-top: 0.4em solid rgb(235, 235, 219);
    border-radius: 1em;
    animation: spinner-rotate 0.66s linear infinite;
}

@keyframes spinner-rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

table {
    width: 100%;
}

footer {
    background-color: #272727;
    color: #fff;
    padding: 1rem 0 2rem;

    img.partners {
        max-width: 60px;
        vertical-align: middle;
    }

    a {
        &:link,
        &:visited {
            color: $yellow;

            &[target="_blank"] {
                text-decoration: none;
            }
        }

        &:hover,
        &:focus {
            color: $lightYellow;
            text-decoration: none;
        }

        &:active {
            background-color: $yellow;
            color: black;

            &[target="_blank"] {
                background-color: transparent;
            }
        }
    }

    nav {
        margin-bottom: 1rem;
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }
    }
}

// 692px
@media screen and (max-width: 43.25rem) {
    .container,
    .container-fluid {
        padding: 1rem;
    }

    .grid {
        grid-template-columns: 100%;
    }

    .flex {
        flex-direction: column;
    }

    .praticien {
        clear: both;
        text-align: center;
        margin-bottom: 1rem;

        &:nth-of-type(2n) {
            text-align: center;

            .praticien-photo {
                float: none;
            }
        }

        .praticien-photo {
            margin: 0 2rem;
            text-align: center;
            float: none;
        }

        .praticien-photo > img {
            filter: grayscale(100%);
            border-radius: 90rem;
            max-width: 12rem;
        }
    }

    #seances {
        .seance-body {
            padding: 0;
        }
    }

    #form-rdv fieldset {
        max-width: initial;
    }

    footer {
        text-align: center;
    }
}

@media screen and (max-width: 36.875rem) {
    header {
        h1 {
            text-shadow: 0px 0px 3px rgb(0 0 0);
        }
        nav li {
            margin: 0;
        }

        nav a {
            &:link,
            &:visited {
                padding: 0.6rem 0.9rem;
            }
        }

        small {
            text-align: center;
        }
    }
}

@media screen and (max-width: 35rem) {
    header {
        background-size: cover;
        background-position: 100% 0;
    }
}
